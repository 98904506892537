.container{
    width: 90% ;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}
.tiles {
    width: 105%;
    padding: 25px 25px 0px 25px;
    display: flex;
    justify-content: center;
    align-items: center;

  }