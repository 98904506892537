
.redirect{
    margin: 0.5rem;
}
 
.container-add-as{
    display: flex;
    margin-top: 0rem !important;
    gap: 1rem;
    width: 100%;
    align-items: flex-start;
    justify-content: center;

}
.attributes{
    border: 0.1rem solid rgb(179, 179, 179);
    width: 25rem;
    padding: 1rem;
    border-radius: 1rem !important;
    .countries{
        background: white;
        width: 100%;
        height: 10rem;
    }
    .select{
        margin-top: 1rem;
    }
    .cat{
        margin-bottom: 1rem;
    }
}
.options{
    .tabs{
        border: 0.1rem solid rgb(179, 179, 179);
        border-radius: 1rem;
        margin-bottom: 20px;
        padding: 1rem;
        display: flex;
        width: fit-content;
        height: 10rem;
        gap: 3rem;
        align-items: center;
        .switch{
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          align-items: flex-end;

        }
        .sub-input{
            width: 10rem;
        }
        .sub-def-input{
            width: 15rem;
        }
        .delete{
            width: 50px;
        }
    }
    .add-button{
        margin-top: 2rem;
        border-radius: 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        .add-icon{
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    .save{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        margin-top: 2rem;
    }
}
.dup-header{
    font-weight: 500;
    text-align: center;
}
.country{
    height: 10rem;
    overflow-y: scroll;
    scrollbar-width: 1rem;
    border:solid 0.1rem rgb(158, 158, 158);
    border-radius:0.3rem;
   
}
.spinner-add{
    margin-top: 2rem;
    margin-left: 45vw !important;
}

.country-h{
    display: flex;
    justify-content: space-between;
}
.country-div{
    padding-left: 1rem;
    padding-top: 0.3rem;
}

.lds-text-field.disabled,
.lds-text-field:disabled {
    color: black !important
}

.country-search{
    border-radius: 0.6rem;
    padding: 0.4rem;
    outline: 0;
    border: 0;
    background: rgb(223, 223, 223);
    height: 30px;
    margin-right: 10px;  
    width: 80%;
}

@media screen and (min-width: 1100px) {
    .sub-input{
        width: 5rem;
    }
  }
