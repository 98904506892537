
.overview {
  margin-top: 1rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: start;
  gap: 3rem;
}
.overview-tile {
  margin-top: 1rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: start;
  gap: 1rem;
}
.add-met-cursor{
  pointer-events: none;
}
.disabled-field{
  z-index: 10;
  position: absolute;
  background: rgb(145, 145, 145);
  height: 18rem;
  width: 100%;
  opacity: 20%;
}
.inline {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.inline-box {
  display: flex;
  flex-direction: column;
  align-items: start;

}

.overview-tile-1 {
  display: flex;
  justify-content: start;
  gap: 3rem;
}
.del-SC{

  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 2rem;
}
 .spinner {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-width-box {
  width: 18vw;
}
.select-width {
  width: 17rem;
}
.paraHead {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-left: 35rem;
}
.cross{
  width: 3rem !important;
  height: 3rem !important;
  margin-right: -4.5vw !important;
  background: white;
}
.add-att-button{
  margin-left: 1rem !important;
  margin-top: 2rem;
  margin-bottom: 2rem !important;
  border-radius: 1.5rem !important;
  display: flex !important;
  align-items: center;
  gap: 1rem;
  .add-icon{
      width: 1.5rem;
      height: 1.5rem;
  }
}
.AddAtri {
  width: 10rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.Buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.select-one {
  align-items: left;
}
.switches{
  font-size: 1rem;
  display: flex;
  width: 18rem;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 1rem;
  margin-right: 2.5rem;
}
.main-entries{
  display: flex;
  justify-content: start;
  width: 100%;
}
.subdiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 95vw;
  height: 15rem !important;
  border: 1px solid rgb(160, 160, 160);
  border-radius:1rem;
  padding:  3rem 0rem 3rem 0rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-left: 1rem;

}
.ov-tile{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.heading1 {
  font-weight: 600;
}

.optionsize {
  width: 30rem;
  margin-left: 2rem;

}

.remarks-div {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-left: -10rem;
  height: 6rem;
  width: 80vw;
  word-wrap: break-word;
  text-decoration: none;
  color: black
}

.inline-reject{
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.reject-comment {
  background-color: #f3ebeb8e;
  display: flex;
  justify-content: center;
  min-height: 13vh;
  max-height: 20vh;
  width: 60vw;
}

.card {
  text-decoration: none;
  color: black
}
.reject-heading {
  font-weight: bold;
  min-width: fit-content;
}
.asterik-required{
color: red;
font-size: 1.3rem
}