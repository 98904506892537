.heading{
    text-align: center;
    padding: 0px 490px;
}
.attriDiv2 {
    justify-content: right;
    // margin-left: 1rem;
    display: flex;
  }
.attriDiv {
    display: flex;
    justify-content: left;
    }
.table-body{
    z-index: 1;
}
.options-medcom-ep{
    margin-bottom: 1.5rem;
    font-weight: 600 !important;
    display: flex;
    gap: 2rem;
    // margin-left: 40vw ;
    font-size: 1.2rem;
    justify-content: center;
    align-items: center;
}
.catTable{
    .lds-table-container.md-break table {
      width: 70%;
      margin: 0 auto;
    }
    .lds-table-container.md-break thead th > button, .lds-table-container.md-break thead th > div {
        padding: 0px;
    }
    .lds-checkbox-block-label {
        padding: 0.2rem;
    }
    .lds-table-container.md-break thead th:first-child > button, .lds-table-container.md-break thead th:first-child > div {
    padding: 1.5rem 1rem calc(1.5rem - 2px) 0.5rem;
    }
    .lds-table-container.md-break thead {
        display: contents
    }
padding-bottom: 20px;
 .lds-table-container.md-break thead th:first-child {
    width: 20%
 }
}
.place{
    display: flex;
    float: right;
    justify-content: flex-end;
    padding: 30px;
    margin-top: -30px;
}
.tableDiv {
    overflow-x: auto
  }
.columns{
    text-align :right;
}
.tablerow{
    border-bottom: 3px solid red;
  }
  .align {
    align-items: center;
    text-align: center;
    width: 100%;
  }